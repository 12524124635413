export const SERVICE_AMOUNT_TYPE = {
    HOUR: {index: 0, label: '小时', value: 'hour', cssClass: 'info'},
    DAY: {index: 1, label: '天', value: 'day', cssClass: 'info'},
    WEEK: {index: 2, label: '周', value: 'week', cssClass: 'info'},
    MONTH: {index: 3, label: '月', value: 'month', cssClass: 'info'},
};

/**
 * 用户服务商状态
 */
export const USER_SERVICE_STATE = {
    NOT_CHECK: {label: '未审核', value: 0, cssClass: 'info'},
    CHECK_PASS: {label: '已审核', value: 1, cssClass: 'info'},
    IN_CHECK: {label: '审核中', value: 2, cssClass: 'info'},
};

export const PUSH_SERVICE_STATE = {
    IN_CHECK: {label: '审核中', value: '1', cssClass: 'warn'},
    WAIT_UP: {label: '待上架', value: '2', cssClass: 'warn'},
    ON_SHELF: {label: '已上架', value: '3', cssClass: 'success'},
    CHECK_FAILED: {label: '审核不通过', value: '4', cssClass: 'error'},
};

export const PUSH_SERVICE_OPTION = {
    UP: {label: '上架', value: '1', cssClass: 'warn'},
    DOWN: {label: '下架', value: '2', cssClass: 'warn'},
};

<template>
  
  <div class="card-container">
    <div class="items" @click="GetClick">
      <div class="image">
        <el-image :src="item.serviceImgs ? item.serviceImgs[0] : ''">
          <div slot="error" class="image-slot">
          </div>
        </el-image>
      </div>
      
      <div class="card-info" v-if="Show">
        <div class="desc">{{ item.serviceTitle }}</div>
        <div class="type">
          <!-- 服务标签 -->
          <span v-for="(item, index) in service_label(item.serviceLabel)" :key="index" class="label">{{ item }}</span>
          <!-- 平台 -->
          <!--<span class="content">{{ platformId(item.platformId) }}</span>-->
        </div>
        <div class="info-container">
          <div>
            <span class="score">{{ item.score }}</span> <span class="state" :class="item.levelClass">{{
              item.scoreLevel
            }}</span>
          </div>
          <span class="address">地区:{{ item.address }}</span>
          <span>好评：{{ item.goodReviewsNumber }}</span>
          <span class="count">销量：{{ item.orderNum }}</span>
        </div>
        <hr class="divider" />
        <div class="option-row">
          <div class="price">
            <span>￥</span>
            <span class="price-number">{{ item.serviceAmountHour }}</span>
            <span>元</span>
            <span>/</span>
            <span>时</span>
          </div>
        </div>
        <div class="shop-type" v-if="providerId === $store.getters.getUser.id">
          <div v-if="item.serviceState === PUSH_SERVICE_STATE.IN_CHECK.value" style="color: #fea800">审核中</div>
          <div v-if="item.serviceState === PUSH_SERVICE_STATE.WAIT_UP.value" style="color: #00ab4c">审核通过</div>
          <div v-if="item.serviceState === PUSH_SERVICE_STATE.ON_SHELF.value" style="color: #00ab4c">已上架</div>
          <div v-if="item.serviceState === PUSH_SERVICE_STATE.CHECK_FAILED.value" style="color: #fc3737">审核未通过</div>
          <div v-if="providerId === userId">
            <el-button type="success" style="margin-left: 15px" v-if="item.serviceState === PUSH_SERVICE_STATE.WAIT_UP.value" size="mini" round plain
                       @click.stop="groundServer">上架
            </el-button>
            <el-button type="success" style="margin-left: 15px" v-if="item.serviceState === PUSH_SERVICE_STATE.WAIT_UP.value" size="mini" round plain
                       @click.stop="groundEdit(item.id)">编辑
            </el-button>
            <el-button type="danger" style="margin-left: 15px" v-if="item.serviceState === PUSH_SERVICE_STATE.ON_SHELF.value" size="mini" round plain
                       @click.stop="offShelf">下架
            </el-button>
          </div>
        </div>
      </div>
      
      <div class="card-info" v-else>
        <div class="shoping">
          <div class="user">
            <el-avatar :size="42" :src="user.avatarImg"></el-avatar>
            <div class="Name">{{ provider.providerName }}</div>
            <el-tag size="mini" effect="dark" class="money">
              <i class="icon iconfont icon-safe" />
              安心购
            </el-tag>
            <el-tag size="mini" effect="dark" class="money">保证金{{ provider.deposit }}</el-tag>
            <el-tag size="mini" effect="dark" class="type"
                    :class="+provider.providerType === 1 ? '' : 'business'"
            >{{ provider.providerTypeLabel }}
            </el-tag>
          </div>
        </div>
        <div class="desc">{{ item.serviceTitle }}</div>
        <div class="type">
          <!-- 服务标签 -->
          <span v-for="(item, index) in service_label(item.serviceLabel, 3)" :key="index" class="label">{{
              item
            }}</span>
          <!-- 平台 -->
          <span class="content">服务平台：{{ platformId(item.platformId) }}</span>
        </div>
        <div class="info-container">
          <div>
            <span class="score">{{ item.score }}</span> <span class="state" :class="item.levelClass">{{
              item.scoreLevel
            }}</span>
          </div>
          <span class="address">地区:{{ item.address }}</span>
          <span>好评：{{ item.goodReviewsNumber }}</span>
          <span class="count">销量：{{ item.orderNum }}</span>
        </div>
        <hr class="divider" />
        <div class="option-row">
          <div class="price">
            <span>￥</span>
            <span class="price-number">{{ item.serviceAmountHour }}</span>
            <span>元</span>
            <span>/</span>
            <span>时</span>
          </div>
          <div class="options">
            <el-button type="primary" size="mini" plain @click.stop="clickShowChat">在线咨询</el-button>
            <el-button type="primary" size="mini" @click.stop="GetOrder">立即雇用</el-button>
          </div>
        </div>
      </div>
    
    
    </div>
    <div class="modal-bg" :class="{active: enableManage}" @click="handleSelect">
      <img alt="" src="../../../assets/imgs/select/selected.png" v-if="selected" class="select" />
    </div>
  </div>

</template>
<script>
// list1 需要判断排版数量
import { mapActions } from "vuex";
import { PUSH_SERVICE_OPTION, PUSH_SERVICE_STATE } from "@/constants/service";

export default {
  props: {
    items: {
      type: Object,
      default: () => {
        return {};
      },
    },
    Num: {
      type: Number,
    },
    Show: {
      type: Boolean,
      default: true,
    },
    rule: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isServe: {
      type: Boolean,
      default: false,
    },
    enableManage: {
      type: Boolean,
      default: false,
    },
    
  },
  data() {
    return {
      id: "",
      userId: "",
      providerId: '',
      selected: false
    };
  },
  mounted() {
    this.id = this.items.id;
    this.userId = this.$store.getters.getUser.id;
    this.providerId = this.items.userId
  },
  computed: {
    PUSH_SERVICE_STATE() {
      return PUSH_SERVICE_STATE
    },
    provider() {
      let provider = this.items?.provider ?? {};
      if (!provider.providerScore) provider.score = 0
      provider.score = provider.score.toFixed(1)
      if (provider.score >= 4.5) {
        provider.scoreLevel = '优'
        provider.levelClass = 'danger'
      } else if (provider.score >= 4) {
        provider.scoreLevel = '良'
        provider.levelClass = 'warn'
      } else {
        provider.scoreLevel = '差'
        provider.levelClass = 'info'
      }
      if (provider.providerType) provider.providerTypeLabel = +provider.providerType === 1 ? '个' : '企'
      return provider;
    },
    user() {
      return this.items?.user ?? {};
    },
    item() {
      let item = this.items;
      
      if (!item.provider.providerScore) item.score = 0
      item.score = item.score.toFixed(1)
      if (item.score >= 4.5) {
        item.scoreLevel = '优'
        item.levelClass = 'danger'
      } else if (item.score >= 4) {
        item.scoreLevel = '良'
        item.levelClass = 'warn'
      } else {
        item.scoreLevel = '差'
        item.levelClass = 'info'
      }
      
      if (!item.deposit) item.deposit = 0
      item.deposit = item.deposit.toFixed(0)
      if (!item.goodReviewsNumber) item.goodReviewsNumber = 0
      if (item.providerType) item.providerType = +item.providerType === 1 ? '个' : '企'
      let cityList = this.$store.getters.getCity;
      if (cityList && cityList.length) {
        let tmp = cityList.find(city => city.id === item.provider.providerProvince)
        if (tmp) item.provinceName = tmp.name
        let cityTmp = tmp.children.find(city => city.id === item.provider.providerCity)
        if (cityTmp) item.cityName = cityTmp.name
        item.address = item.provinceName + '-' + item.cityName
      }
      
      
      if (item.serviceImg) item.serviceImgs = item.serviceImg.split(',')
      if (item.serviceImgs && item.serviceImgs.length) item.serviceImg = item.serviceImgs[0]
      return item
    }
  },
  watch: {
    enableManage: function () {
      this.selected = false
    }
  },
  
  methods: {
    ...mapActions(['showChat']),
    GetClick() {
      this.$emit("GetClick", this.items.id);
    },
    
    // 下架服务
    offShelf() {
      this.$confirm("确定下架该服务？")
          .then(() => {
            this.$axios
                .put(`/app-api/hiring/push-service/state`, {
                  id: this.id,
                  state: PUSH_SERVICE_OPTION.DOWN.value
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.$message({
                      message: "下架成功！",
                      type: "success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 1000);
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: "下架失败！" + err,
                    type: "error",
                  });
                });
          })
      // .catch(() => {
      //   this.$message.info('已取消删除');
      // });
      
    },
    
    // 上架服务
    groundServer() {
      this.$confirm("确定上架该服务？")
          .then(() => {
            this.$axios
                .put(`/app-api/hiring/push-service/state`, {
                  id: this.id,
                  state: PUSH_SERVICE_OPTION.UP.value
                })
                .then((res) => {
                  // console.log(res, "res");
                  if (res.data.code === 0) {
                    this.$message({
                      message: "上架成功！",
                      type: "success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 1000);
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: "上架失败！" + err,
                    type: "error",
                  });
                });
          })
    },
    // 编辑服务
    groundEdit(id) {
      this.$router.push({
        path: "/UserInfo/serveDetail?id=" + id,
      });
    },
    
    handleSelect() {
      this.selected = !this.selected;
      if (this.selected) {
        this.$emit('itemSelect', true, this.id)
      } else {
        this.$emit('itemSelect', false, this.id)
      }
    },
    clickShowChat() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      if (this.user.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: this.user.userPhone,
        goods: {
          id: this.items.id,
          name: this.items.serviceTitle,
          desc: this.items.serviceDescribe,
          img: this.items.serviceImg
        }
      });
    },
    GetOrder() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      // 自己不能下单
      if (this.$store.getters.getUser.id === this.items.user_id)
        return this.$modal.msgWarning("禁止给自己下单!");
      let url = "/app-api/hiring/service-order/submit";
      this.$axios
          .post(url, {
            orderUserId: this.$store.getters.getUser.id,
            serviceId: this.items.id,
            serviceAmountType: 'hour',
            orderNum: 1,
            orderAmount: this.items.serviceAmountHour
          })
          .then((res) => {
            if (res.data.code === 0) {
              // this.$modal.msgSuccess(res.data.message);
              // 订单页面
              // this.$router.push({ path: "/UserInfo/Order" });
              // 出现支付弹框
              // 价格判断
              this.$modal.notifySuccess("订单创建成功!");
              // 倒计时
              // let price = this.shopPrice * this.shopNum;
              // 跳转到 付款页面
              this.$router.push({
                path: "/Order/Payment",
                query: { id: res.data.data },
              });
              // 跳转到支付页面
              // this.$router.push({
              //   name: "Buy",
              //   params: {
              //     id: res.data.data,
              //     title: this.ServiceInfo.serviceTitle,
              //     price,
              //     time: new Date().getTime(),
              //   },
              // });
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: din-bold; //自定义字体名称
  src: url('../../../assets/font/DIN Bold.otf'); //注意引入正确位置
}

.items {
  width: 341px;
  height: 460px;
  background: #ffffff;
  border-radius: 9px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.items_num {
  &:last-of-type {
    margin-right: 0 !important;
  }
}

.items_num4 {
  &:nth-child(4n) {
    margin-right: 0 !important;
  }
  
  &:nth-child(n + 5) {
    margin-top: 40px !important;
  }
}

.image {
  width: 100%;
  height: 222px;
  background: #adadad;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  
  img {
    width: 100%;
    height: 100%;
  }
  
  /deep/ .el-image__error {
    color: #ffffff !important;
    background: none !important;
  }
  
  /deep/ .el-image__placeholder {
    background: none !important;
  }
}

.card-info {
  width: 100%;
  padding: 10px 14px 0;
  box-sizing: border-box;
  height: calc(100% - 222px);
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  border-bottom: 1px solid #ddd;
  
  .shoping {
  }
  
  .shop-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    div {
      font-size: 14px;
    }
    
    .grounding {
      color: #00ab4c;
    }
    
    .error {
      color: #fc3737;
    }
    
    .progress {
      color: #fea800;
    }
  }
  
  .user {
    display: flex;
    align-items: center;
    
    .Name {
      color: #333;
      font-size: 12px;
      margin-left: 11px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .el-tag {
      margin: 0 0 0 5px;
      border: none;
      line-height: 20px;
      
      &:nth-of-type(1) {
        margin-left: 20px;
      }
      
      &.tag {
        background-color: #FFB40E;
      }
      
      &.type {
        background-color: #F46700;
      }
      
      &.business {
        background-color: #3D8BFF
      }
      
      &.money {
        background-color: #36A04C;
        
        .icon {
          font-size: 14px;
        }
      }
    }
  }
  
  .desc {
    font-weight: 600;
    color: #555;
    font-size: 15px;
    line-height: 18px;
    margin: 10px 0;
    height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  
  .type {
    margin-top: 6px;
    display: flex;
    align-items: center;
    
    .label {
      // width: 42px;
      padding: 0 6px;
      height: 18px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #fc3737;
      color: #fc3737;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
    }
    
    .content {
      color: #555;
      font-size: 12px;
      margin-left: 6px;
      font-weight: 600;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
  .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin: 10px 0;
    color: #B18021;
    
    .score {
      color: #fc3737;
    }
    
    .count {
      padding-left: 30px;
    }
    
    .address {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
  .divider {
    border: 0;
    border-bottom: 1px dashed #B18021;
    margin: 10px 0;
  }
  
  .option-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    
    .price {
      display: flex;
      align-items: baseline;
      
      
      span {
        color: #fc3737;
        font-size: 16px;
        
        & + span {
          margin-left: 2px;
        }
        
        &.price-number {
          font-size: 24px;
          font-weight: bold;
          font-family: din-bold, serif;
          margin-left: -2px;
          max-width: 6ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      
    }
  }
}

.el-image {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  
  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.state {
  color: #666666;
  
  &.info {
    color: #999999;
  }
  
  &.success {
    color: #209F85;
  }
  
  &.warn {
    color: #ee914e;
  }
  
  &.danger {
    color: #FF3D3D;
  }
}

.cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  margin-top: 6px;
  
  .num {
    color: #b18021;
    font-size: 12px;
    width: 100px;
  }
  
  .price {
    display: flex;
    align-items: baseline;
    
    span {
      color: #fc3737;
      font-size: 16px;
      
      & + span {
        margin-left: 2px;
      }
      
      &.price-number {
        font-size: 24px;
        font-weight: bold;
        font-family: din-bold, serif;
        margin-left: -2px;
      }
    }
    
  }
}


.card-container {
  position: relative;
}

.modal-bg {
  display: none;
  
  &.active {
    display: block;
    width: 343px;
    height: 462px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    //border: 1px solid #ddd;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .select {
    position: absolute;
    width: 30px;
  }
}
</style>

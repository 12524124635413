<template>
  <div>
    <el-card class="box-card">
      <!-- 商品 -->
      <div class="contents">
        <div class="head">
          <div class="title">
            商品
            <span>（{{ total }}）</span>
          </div>
          
          <div class="manage-container">
            <div class="manage-item add_serve" @click="manage">
              <i class="el-icon-setting">管理</i>
            </div>
            <div class="manage-item add_serve" @click="goRelease">
              <i class="el-icon-circle-plus-outline" style="color: #409eff">发布服务</i>
            </div>
          </div>
        
        </div>
        
        <el-empty v-if="showEmpty" :image="require('@/assets/imgs/no_hall.png')"
                  description="你还未发布服务"></el-empty>
        <div class="main" v-show="!showEmpty">
          <el-row v-for="(page, index) of pages" :key="index" type="flex" :gutter="25">
            <el-col :span="8" v-for="(item) of page" :key="item.id">
              <list1 :items="item"
                     @GetClick="clickList"
                     :enableManage="enableManage"
                     @itemSelect="itemSelect"
              ></list1>
            </el-col>
          </el-row>
          <Pagination :total="total" :size="queryParam.pageSize" :page="queryParam.pageNo" @handlePage="PageChange"
                      v-if="total > queryParam.pageSize" />
        </div>
        
        <div class="bottom" v-if="enableManage">
          <el-row type="flex" justify="end">
            <el-button type="info" plain class="normal" @click="cancelManage">取消</el-button>
            <el-button type="danger" @click="deleteService">删除</el-button>
          </el-row>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import List1 from "@/components/List/components/list1.vue";
import Pagination from "@/components/Pagination/index.vue";

export default {
  
  data() {
    return {
      currentPage4: 1, // 默认页
      list: [], // 服务列表
      itemList: [], // 平台列表
      id: "", // 用户id
      UserInfo: "", //用户信息
      showEmpty: false,
      
      enableManage: false,
      selectedId: new Set(),
      
      total: 0,
      queryParam: {
        userId: this.$store.getters.getUser.id,
        pageNo: 1,
        pageSize: 6,
      }
    };
  },
  components: {
    List1,
    Pagination
  },
  created() {
    this.id = this.$store.getters.getUser.id;
    this.getServiceList();
    
    // 获取下拉内容
    // this.getSelect();
  },
  mounted() {
  
  },
  computed: {
    pages() {
      if (!this.list || !this.list.length) return
      const pages = []
      this.list.forEach((item, index) => {
        const page = Math.floor(index / 3)//4代表4条为一行，随意更改
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  },
  methods: {
    // 获取用户发布服务
    getServiceList() {
      let url = `/app-api/hiring/push-service/page`;
      
      this.$axios
          .get(url, { params: this.queryParam })
          .then((res) => {
            if (res.data.code === 0) {
              this.list = res.data.data.list;
              this.total = res.data.data.total
              if (!this.list) {
                this.showEmpty = true;
              }
              this.showEmpty = false;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    PageChange(val) {
      this.queryParam.pageNo = val;
      this.getServiceList();
    },
    
    // 查看服务
    clickList(id) {
      this.$router.push({
        path: "/UserInfo/serveDetail?id=" + id,
      });
    },
    
    // 发布服务
    goRelease() {
      if (this.$store.getters.getUser.service_status == 0)
        return this.$modal.notifyWarning("请先加入赚钱!");
      this.$router.push({
        path: "/PublishingService",
      });
    },
    
    manage() {
      this.enableManage = true
    },
    cancelManage() {
      this.enableManage = false
    },
    
    itemSelect(isSelected, id) {
      isSelected ? this.selectedId.add(id) : this.selectedId.delete(id)
      // console.log(this.selectedId)
    },
    deleteService() {
      this.$confirm("确定删除所选服务吗？")
          .then(() => {
            this.$axios
                .delete(
                    `/app-api/hiring/push-service/batch-delete`, {
                      params: {
                        ids: Array.from(this.selectedId).join(',')
                      }
                    }
                )
                .then((res) => {
                  if (res.data.code === 0) {
                    this.$message.success('删除成功!');
                    this.enableManage = false
                    this.selectedId = new Set()
                    this.getServiceList()
                    // setTimeout(() => {
                    //   location.reload();
                    // }, 1000);
                  }
                })
                .catch((err) => {
                  this.$message.error("删除失败！" + err)
                });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
    }
  },
};
</script>
<style scoped lang="less">
.list1 {
  // margin-bottom: 20px;
  margin: 0 0 20px 0;
}

.el-row + .el-row {
  margin-top: 20px;
}

.items {
  &:nth-child(3n) {
    margin-right: 0 !important;
  }
}

// 商品
.contents {
  padding: 10px;
  
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    
    .add_serve {
      cursor: pointer;
      font-size: 18px;
    }
  }
  
  .main {
    margin: 20px 0;
  }
}


.manage-container {
  display: flex;
  
  .manage-item:not(:nth-of-type(1)) {
    margin-left: 20px;
  }
}

.bottom {
  .el-button {
    width: 100px;
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    background: #E9E9E9;
    color: black;
    
    &:hover {
      background: #c5c5c5;
    }
    
    &.el-button--danger {
      background: #FC3737;
      color: white;
      
      &:hover {
        background: #f78989;
      }
    }
    
    & + el-button {
      margin-left: 50px;
    }
  }
}

</style>
